import axios from "axios";
import { IOlympicSport, olympicSports } from "../helpers/json/sports";

const BASE_URL = "https://calendario.skidun.com/api";

export interface IOlympicMatch {
	"id": number;
	"codigo": string;
	"descricao": string;
	"inicio_utc": string;
	"inicio_brasil": string;
	"final_utc": string;
	"final_brasil": string;
	"medalha": string;
	"selecoes": [
		{
			"id": number;
			"codigo": string;
			"descricao": string;
		},
		{
			"id": number;
			"codigo": string;
			"descricao": string;
		}
	];
	"codigo_local": string;
	"local": string;
	"codigo_esporte": string;
	"esporte": string;
}
export interface IOlympicEvent {
	jogos: IOlympicMatch[];
	name: string;
	name_pt_br: string;
	codigo: string;
	descricao: string;
	icon: string;
	id: number;

	detail?: string;
	datas?: string[];
	meses?: number[];
}

export interface IMedalBoardItem {
	"selecao_codigo": string;
	"selecao_descricao": string;
	"posicao": number;
	"ouro": number;
	"prata": number;
	"bronze": number;
	"total_medalhas": number;
}

export interface ILead {
	"nome": string;
	"email": string;
	"empresa": string;
	"segmento": string;
	"cargo": string;
	"jogos": number[];
}

export interface ILeadResponse {
	apple: boolean;
	cargo: string;
	email: string;
	empresa: string;
	google: boolean;
	id: number;
	jogos: number[];
	nome: string;
	segmento: string;
	url_apple: string;
	url_google: string;
}

export interface IDatasResponse {
	esporte: string;
	meses: number[];
	datas: string[];
}

export interface IEVentError {
	detail?: string;
	datas?: string[];
	meses?: number[];
}

const formatAllEventsResponse = (spo: any) => {
	const esporteFiltrado = olympicSports.find((s: IOlympicSport) => s.name_pt_br.toLocaleLowerCase() === spo.descricao.toLowerCase());
	return {
		...spo,
		"name_pt_br": spo.descricao,
		"name": esporteFiltrado?.name,
		"icon": esporteFiltrado?.icon,
	};
};

export async function getAllEvents(filter?: string): Promise<IOlympicEvent[]> {
	try {
		const response = await axios.get(`${BASE_URL}/eventos_esporte${filter ? `/${filter}` : ""}`);
		const resposta = response.data.map((spo: any) => formatAllEventsResponse(spo));
		return resposta;
	} catch (error) {
		//@ts-ignore
		// console.log(error.response.data);
		//@ts-ignore
		return error.response.data;
	}
}

export async function getAllMedalhas(): Promise<IMedalBoardItem[]> {
	try {
		const response = await axios.get(`${BASE_URL}/medalhas`);
		return response.data;
	} catch (error) {
		alert("Houve um erro ao buscar o quadro de medalhas disponíveis, tente novamente mais tarde.");
		console.error(error);
		return [
			{
				bronze: 0,
				posicao: 1,
				ouro: 0,
				prata: 0,
				selecao_codigo: "",
				selecao_descricao: "",
				total_medalhas: 0,
			},
		];
	}
}

export async function getDatasPorEsporte(codigoEsporte: string, brasil: boolean = false): Promise<IDatasResponse> {
	try {
		const response = await axios.get(`${BASE_URL}/datas/${codigoEsporte !== "" ? `?esporte=${codigoEsporte}` : ""}${codigoEsporte !== "" ? "&" : "?"}${brasil ? "selecoes=brasil" : ""}`);
		return response.data;
	} catch (error) {
		alert("Houve um erro ao buscar as datas disponíveis, tente novamente mais tarde.");
		console.error(error);
		return {
			esporte: "",
			meses: [],
			datas: [],
		};
	}
}

export async function postLead(lead: ILead): Promise<ILeadResponse | undefined> {
	try {
		const response = await axios.post(`${BASE_URL}/agenda/`, lead);
		return response.data;
	} catch (error) {
		alert("Houve um erro, tente novamente mais tarde.");
		console.error(error);
	}
}

export async function getIcsLink(jogos: string): Promise<string> {
	try {
		const response = await axios.get(`${BASE_URL}/apple/?jogos=${jogos}`);
		return response.data;
	} catch (error) {
		alert("Houve um erro ao gerar o link para o arquivo .ics, tente novamente mais tarde.");
		console.error(error);
		return "";
	}
}
