export const leadSegments = [
	"Agência de Marketing e Publicidade",
	"Agronegócio",
	"Consultorias e Treinamentos",
	"Ecommerce",
	"Educação e Ensino",
	"Engenharia e Indústria Geral",
	"Eventos",
	"Financeiro/Jurídico ou Serviços Relacionados",
	"Governo e Órgãos Públicos",
	"Hardware e Eletrônicos",
	"Mídia e Comunicação",
	"ONGs",
	"Saúde e Estética",
	"Serviços em Geral",
	"Serviços em RH e Coaching",
	"Software e Cloud",
	"Telecomunicações",
	"Turismo e Lazer",
	"Varejo",
];

export const leadJobs = ["Analista", "Coordenador", "Diretor", "Gerente", "Outros"];
export const leadArea = ["Branding", "Conteúdo", "CRM", "Eventos", "Marketing", "Mídia", "Redes Sociais", "Outros"];
