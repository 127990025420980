import React, {
    useRef,
    useEffect,
    useState,
    useCallback
} from "react";
// import { Link } from "react-router-dom";
import getLang from "../helpers/getLang";
import { Header } from "../components/header";
import { IOlympicSport, olympicSports } from "../helpers/json/sports";
import medal_icon from "../assets/images/icons/medal.svg";
import map_marker_icon from "../assets/images/icons/map_marker.svg";
// import regular_evt_icon from "../assets/images/icons/reg_event.svg";
// import exp_evt_icon from "../assets/images/icons/ex_event.svg";
import olympic_rings from "../assets/images/icons/sports/olympic-rings.svg";
import gold_medal from "../assets/images/icons/gold.svg";
import silver_medal from "../assets/images/icons/silver.svg";
import bronze_medal from "../assets/images/icons/bronze.svg";
import medals_icon from "../assets/images/icons/medals.svg";
import close_button from "../assets/images/icons/close_lighbox_button.png";
import checkbox_true from "../assets/images/icons/checked_box.png";
import checbox_false from "../assets/images/icons/unchecked_box.png";
// import hero_banner_footer from "../assets/images/cortar_footer_calendario_desktop.webp";
import loading_gif from "../assets/images/loading.gif";
import arrow_right from "../assets/images/icons/arrow_right.svg";
import {
    IDatasResponse, //
    ILead,
    IMedalBoardItem,
    IOlympicEvent,
    IOlympicMatch,
    getAllEvents,
    getAllMedalhas,
    getDatasPorEsporte,
    postLead
} from "../services/sportsService";
import { leadArea, leadJobs } from "../helpers/json/lead_segments";
import { useNavigate } from "react-router-dom";
import { BackToTop } from "../components/backtotop";
import { Footer } from "../components/footer";
// import { useHorizontalScroll } from "../helpers/useHorizontalScroll";

export interface Props {
}

const Calendar: React.FC<Props> = () => {
    const lang = getLang();
    const [sportsNavArrows, setSportsNavArrows] = useState<{ right: boolean, left: boolean }>({
        right: true,
        left: false
    });
    const [loadingLead, setLoadinLead] = useState<boolean>(false);
    const [loadingMedals, setLoadingMedals] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [olympicEvents, setOlympicEvents] = useState<IOlympicEvent[]>([]);
    const [medals, setMedals] = useState<IMedalBoardItem[]>([]);
    // const [medalsBr, setMedalsBr] = useState<IMedalBoardItem>();
    const [month, setMonth] = useState<'july' | 'august'>('july');
    const [day, setDay] = useState<number>(24);
    const [sportFilter, setSportFilter] = useState<string>("Todos");
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [datasDisponiveis, setDatasDisponiveis] = useState<{ datas: string[], meses: number[] }>({
        datas: [],
        meses: [7, 8]
    })
    const [selectedEvent, setSelectedEvent] = useState<{
        sport: string,
        date: string,
        match: string,
        icon: string,
        id: number,
    }>();

    const [leadForm, setLeadForm] = useState<ILead>({
        nome: "",
        email: "",
        cargo: "Analista",
        segmento: "Agência de Marketing e Publicidade",
        empresa: "",
        jogos: []
    })

    const todasDatas = {
        "july": [
            24, 25, 26, 27, 28, 29, 30, 31
        ],
        "august": [
            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11
        ]
    }

    const [checkbox, setCheckbox] = useState<boolean>(false);
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const ulSportsScrollRef = useRef<HTMLDivElement>(null);

    const navigate = useNavigate();

    // const onWheel = (e: UIEvent) => { // mudar scroll vertical para horizontal no elemento da lista de esportes
    //     const elelemnt = ulSportsScrollRef.current;
    //     if (elelemnt) {
    //         //@ts-ignore
    //         if (e.deltaY === 0) return;
    //         elelemnt.scrollTo({
    //             //@ts-ignore
    //             left: elelemnt.scrollLeft + e.deltaY,
    //         });
    //     }
    // };

    const handleShowEventModal = (sport: IOlympicEvent, evento: IOlympicMatch) => {
        let match = evento.descricao
        if (evento.selecoes.length > 0) {
            match = `${evento.selecoes[0].descricao} x ${evento.selecoes[1].descricao}`
        }

        const sEvt = {
            "sport": lang['code'] === "pt_BR" ? sport.name_pt_br : sport.name,
            "date": new Date(evento.inicio_brasil).toLocaleDateString("pt-br", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit"
            }),
            match,
            "icon": sport.icon,
            id: evento.id
        }

        setSelectedEvent(sEvt)

        localStorage.setItem("sportSkidunCalendario", JSON.stringify(sEvt));

        if (localStorage.getItem("leadSkidunCalendario")) {
            handlePostLead();
            // navigate("/thanks");
        } else {
            setModalOpen(true)
        }
    }

    // const pauseVerticalScroll = (pause: boolean) => {
    //     if (pause) {
    //         document.querySelector("body")?.classList.add("stopScroll")
    //     } else {
    //         document.querySelector("body")?.classList.remove("stopScroll")
    //     }
    // }

    const handleChangeMonth = (m: "july" | "august") => {
        setMonth(m);
        setDay(m === "august" ? 1 : 24);
    }

    const handleLeadForm = (e: any, field: string) => {
        setLeadForm({ ...leadForm, [field]: e.target.value })
    }

    const handlePostLead = async (e?: any) => {
        if (e) {
            e.preventDefault();
        }
        setLoadinLead(true);
        //@ts-ignore
        const sevt = localStorage.getItem("sportSkidunCalendario") ? JSON.parse(localStorage.getItem("sportSkidunCalendario")) : null;
        if (sevt !== null) {
            let res: any = "";

            const l = localStorage.getItem("leadSkidunCalendario");

            if (l && l !== null) {
                res = await postLead({
                    ...JSON.parse(l),
                    jogos: [sevt.id]
                })
            } else {
                res = await postLead({
                    ...leadForm,
                    jogos: [sevt.id]
                })
            }

            console.log(res)
            if (res) {
                if (!l || l === null) {
                    localStorage.setItem("leadSkidunCalendario", JSON.stringify({
                        ...leadForm,
                        jogos: [sevt.id]
                    }));
                } else {
                    localStorage.setItem("leadSkidunCalendario", JSON.stringify({
                        ...JSON.parse(l),
                        jogos: [sevt.id]
                    }))
                }
                localStorage.setItem("googleLinkSkidunCalendario", res.url_google[0]);
                localStorage.setItem("icsJogosSkidunCalendario", res.url_apple.split("=")[1]);
            }
        }
        setLoadinLead(false);
        navigate("/thanks");
    }

    const handleBrazilCheckbox = () => {
        setCheckbox(!checkbox);
    }

    const getEvents = useCallback(async (filter: string = "") => {
        setLoading(true);
        let f = filter;
        const dia = day;
        const mes = month === "august" ? "08" : "07";

        const dateFilter = `2024-${mes}-${dia}`

        if (checkbox) {
            f += "&selecoes=brasil"
        }

        if (sportFilter !== "Todos") {
            f += `&esporte=${sportFilter}`
        }

        const events: IOlympicEvent[] = await getAllEvents(`?date=${dateFilter}${f}`);
        //@ts-ignore
        if (events.detail) {
            //@ts-ignore
            setErrorMessage(events.detail)

            //@ts-ignore
            if (events.meses && events.meses.length > 0) {
                setDatasDisponiveis({
                    //@ts-ignore
                    meses: events.meses,
                    //@ts-ignore
                    datas: events.datas
                })
                //@ts-ignore
                let pDia = parseInt(events.datas[0].split("/")[0]);

                //Identifica se alguma das datas disponíveis inclui o mês selecionado, se sim, seta o dia, se não, seta o primeiro disponível independente do mês.
                //@ts-ignore
                if (events.datas.some(da => da.includes(`/0${month === "august" ? "8" : "7"}`))) {
                    //@ts-ignore
                    pDia = parseInt(events.datas.filter(d => d.includes(`/0${month === "august" ? "8" : "7"}`))[0].split("/")[0]);
                } else {
                    //@ts-ignore
                    pDia = parseInt(events.datas[0].split("/")[0]);
                }
                setDay(pDia);
                //Identifica se se o mê escolhido está disponível e se sim, seta, se não, seta o primeiro disponível.
                //@ts-ignore
                if (events.meses.includes(month === "august" ? 8 : 7)) {
                    setMonth(month)
                } else {
                    //@ts-ignore
                    setMonth(events.meses[0] === 7 ? "july" : "august")
                }
            } else {
                setDatasDisponiveis({
                    meses: [],
                    datas: [],
                })
            }
        } else {
            setErrorMessage("")
            const datas: IDatasResponse = await getDatasPorEsporte(events.length === 1 ? events[0].codigo : "", checkbox);

            setDatasDisponiveis({
                meses: datas.meses,
                datas: datas.datas
            })


            setOlympicEvents(events);
        }

        setLoading(false);
    }, [checkbox, day, month, sportFilter])

    const handleDayChange = (dia: number, dataDisp: boolean) => {
        if (!dataDisp) return;
        setDay(dia);
    }

    const getMedals = useCallback(async () => {
        setLoadingMedals(true);
        const medalsApi: IMedalBoardItem[] = await getAllMedalhas();
        setMedals(medalsApi);
        // setMedalsBr(medalsApi.find((m: IMedalBoardItem) => m.selecao_codigo === "BRA"))
        setLoadingMedals(false);
    }, [])

    useEffect(() => {
        getEvents();
    }, [getEvents])

    useEffect(() => {
        getMedals();
    }, [getMedals])

    useEffect(() => {
        window.scrollTo(0, 0);
        localStorage.removeItem("icsJogosSkidunCalendario")
        localStorage.removeItem("googleLinkSkidunCalendario")
        localStorage.removeItem("sportSkidunCalendario")
        setDay(new Date().getDate());
        setMonth(new Date().getMonth() === 6 ? "july" : "august")
    }, [])
    useEffect(() => {
        const ulS = document.querySelector("#ulSports");
        if (ulS) {
            const maxScroll = ulS.scrollWidth - ulS.clientWidth
            ulS.addEventListener("scroll", (e) => {
                //@ts-ignore
                if (e.target.scrollLeft <= 0) {
                    setSportsNavArrows({ left: false, right: true })
                    //@ts-ignore
                } else if (e.target.scrollLeft >= maxScroll) {
                    setSportsNavArrows({ left: true, right: false })
                } else {
                    setSportsNavArrows({ left: true, right: true })
                }
            })
        }
    }, [])

    const scrollSports = (direction: string) => {

        if (direction !== "right" && direction !== "left") return

        const ul = document.querySelector("#ulSports");

        if (!ul) return;

        const scrollDistance = 400;

        const maxScroll = ul.scrollWidth - ul.clientWidth
        let current = ul.scrollLeft;

        if (direction === "right") {
            ul.scrollTo({ left: ul.scrollLeft + scrollDistance, behavior: "smooth" })
            current += scrollDistance;
        } else {
            ul.scrollTo({ left: ul.scrollLeft - scrollDistance, behavior: "smooth" })
            current -= scrollDistance;
        }

        if (current <= 0) {
            setSportsNavArrows({ left: false, right: true })
        } else if (current >= maxScroll) {
            setSportsNavArrows({ left: true, right: false })
        } else {
            setSportsNavArrows({ left: true, right: true })
        }
    }

    return (<>
        <Header />
        <main className="mainBody">
            <div className="container nopadbot">
                <h3>{lang['search']}</h3>
            </div>
            <div className="arrowsWrapper">
                {["left", "right"].map((i: string) =>
                    <button
                        key={i}
                        className={`navArrow ${i}`}
                        // @ts-ignore
                        disabled={sportsNavArrows[i] ? false : true}
                        title={i}
                        onClick={() => scrollSports(i)}><img src={arrow_right}
                            alt={`Nav ${i}`} aria-hidden="false" /></button>
                )}
            </div>
            {/* @ts-ignore */}
            <ul className="carrosselModalidades" id="ulSports" ref={ulSportsScrollRef}>
                <li onClick={() => setSportFilter("Todos")} className={sportFilter === "Todos" ? "active" : "nonactive"}>
                    <span className="sportIcon"><img src={olympic_rings} alt="All sports" /></span>
                    <small className="sportName">{lang['all_sports']}</small>
                </li>
                {olympicSports.sort((a: IOlympicSport, b: IOlympicSport) => {
                    const textA = lang['code'] === "pt_BR" ? a.name_pt_br.toUpperCase() : a.name.toUpperCase();
                    const textB = lang['code'] === "pt_BR" ? b.name_pt_br.toUpperCase() : b.name.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                }).map((sport: IOlympicSport) => <li key={sport.id} onClick={() => setSportFilter(sport.name_pt_br)} className={sportFilter === sport.name_pt_br ? "active" : "nonactive"}>
                    <span className="sportIcon"><img src={require(`../assets/images/icons/sports/${sport.icon}`)} alt={lang['code'] === "en" ? sport.name : sport.name_pt_br} /></span>
                    <small className="sportName">{lang['code'] === "en" ? sport.name : sport.name_pt_br}</small>
                </li>)}
            </ul>
            <div className="container">
                <div className="flexTopSchedule">
                    <h4>{lang['header_prog']}</h4>
                    <label htmlFor="checkBr" onClick={handleBrazilCheckbox}>
                        <img src={checkbox ? checkbox_true : checbox_false} alt="check" />
                        {lang['only_team_br']} 🇧🇷
                    </label>
                </div>
            </div>
            <section className="container schedule">
                {/* MÊS */}
                <div className="topSchedule">
                    <div className="monthTabs">
                        <div className={month === "july" ? "active" : "nonactive"}>
                            <button onClick={() => handleChangeMonth('july')} disabled={!datasDisponiveis.meses.includes(7)}>{lang['month']['july']}</button>
                        </div>
                        <div className={month === "august" ? "active" : "nonactive"}>
                            <button onClick={() => handleChangeMonth('august')} disabled={!datasDisponiveis.meses.includes(8)}>{lang['month']['august']}</button>
                        </div>
                    </div>
                    <ul className="eventTypes">
                        <li><img src={medal_icon} alt={lang["medal_ev"]} />{lang["medal_ev"]}</li>
                        {/* <li><img src={regular_evt_icon} alt={lang["regular_ev"]} />{lang["regular_ev"]}</li>
                        <li><img src={exp_evt_icon} alt={lang["p_day"]} />{lang["p_day"]}</li> */}
                    </ul>
                </div>
                {/* DIAS */}
                <div className="calendar">
                    <div className="days">
                        <ul>
                            {
                                todasDatas[month].map((dt: number) => {
                                    return <React.Fragment key={dt}>
                                        {
                                            new Date().getTime() < new Date(`2024-${month === "july" ? "07" : "08"}-${dt < 10 ? `0${dt}` : dt}T23:59:59`).getTime() &&
                                            <li key={dt} onClick={() => handleDayChange(dt, datasDisponiveis.datas.map((d: string) => parseInt(d.split("/")[0])).includes(dt))}
                                                className={`${day === dt ? "active" : "nonactive"}  ${datasDisponiveis.datas.map((d: string) => d.split("/")[0]).includes(dt >= 10 ? dt.toString() : `0${dt}`) ? "available" : "disabled"}`}
                                            >
                                                <small>{new Date(`2024-${month === "july" ? "07" : "08"}-${dt < 10 ? `0${dt}` : dt}T00:00:01Z`).toLocaleDateString("pt-BR", { weekday: "short", timeZone: "UTC" })}</small>{dt < 10 ? `0${dt}` : dt}
                                            </li>
                                        }
                                    </React.Fragment>
                                })
                            }
                        </ul>
                    </div>
                </div>
                <p className="topObs">{lang['top_obs']}</p>
                {loading &&
                    <div className="centerLoading">
                        <img src={loading_gif} alt="loading" />
                    </div>}
                {/* {!loading && errorMessage && <p className="topObs error">{errorMessage}</p>} */}
                {!loading && !errorMessage && <>
                    {
                        olympicEvents.sort((a: IOlympicEvent, b: IOlympicEvent) => {
                            const textA = lang['code'] === "pt_BR" ? a.name_pt_br.toUpperCase() : a.name.toUpperCase();
                            const textB = lang['code'] === "pt_BR" ? b.name_pt_br.toUpperCase() : b.name.toUpperCase();
                            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                        }).map((sport: IOlympicEvent) => <section className="sport" key={sport.id} id={`sport-${sport.id}`}>
                            <header>
                                <div><img src={require(`../assets/images/icons/sports/${sport.icon}`)} alt={lang['code'] === "en" ? sport.name : sport.name_pt_br} /></div>
                                <span>{lang['code'] === "en" ? sport.name : sport.name_pt_br}</span>
                            </header>
                            <main>
                                {sport.jogos.map((olympicEvent: IOlympicMatch) =>
                                    // <div className="evento" key={olympicEvent.id} onClick={() => handleShowEventModal(sport, olympicEvent)}>
                                    <div className="evento" key={olympicEvent.id}>
                                        {olympicEvent.medalha !== "Não" &&
                                            <img className="evtIcon" src={medal_icon} alt="medal event" title="medal event" aria-hidden="true" />
                                        }
                                        <div className="evtHora">{
                                            new Date(olympicEvent.inicio_brasil).toLocaleDateString("pt-BR", {
                                                hour: "2-digit",
                                                minute: "2-digit"
                                            }).split(",")[1]
                                        }
                                            {/* :{
                                            new Date(olympicEvent.inicio_brasil).getMinutes()
                                        } */}
                                        </div>
                                        <div className="evtInfo">
                                            <div className="topInfo"><span>{olympicEvent.descricao}</span>
                                                <span><img src={map_marker_icon} alt="marker" aria-hidden="true" /> {olympicEvent.local}</span></div>
                                            {olympicEvent.selecoes.length > 0 && olympicEvent.selecoes.length < 3 ?
                                                <div className="teams">
                                                    {olympicEvent.selecoes[0] ? <>
                                                        <div className="team">
                                                            <span className="name">{olympicEvent.selecoes[0].descricao}</span>
                                                            <span className="flag"></span>
                                                        </div>
                                                    </> : <>
                                                        <div className="team">
                                                            <span className="name">A Definir</span>
                                                            <span className="flag"></span>
                                                        </div>
                                                    </>}
                                                    <div className="vs">X</div>
                                                    {olympicEvent.selecoes[1] ? <>
                                                        <div className="team">
                                                            <span className="name">{olympicEvent.selecoes[1].descricao}</span>
                                                            <span className="flag"></span>
                                                        </div>
                                                    </> : <>
                                                        <div className="team">
                                                            <span className="name">A Definir</span>
                                                            <span className="flag"></span>
                                                        </div>
                                                    </>}
                                                </div>
                                                : <>

                                                    {olympicEvent.selecoes.filter((sel: any) => sel.codigo === "BRA").length > 0 && <>
                                                        <div className="teams">
                                                            <div className="team">
                                                                <span className="name">Brasil</span>
                                                                <span className="flag"></span>
                                                            </div>
                                                        </div>
                                                    </>}

                                                </>}
                                            <button onClick={() => handleShowEventModal(sport, olympicEvent)} disabled={loadingLead}>{lang['add_to_calendar']}</button>
                                        </div>
                                    </div>)}
                            </main>
                        </section>)
                    }</>}
            </section>
            <section className="medalBoard">
                <div className="container thinner">
                    <h5>{lang["medal_headline"]}</h5>
                    <ol>
                        <li className="header">
                            <ul>
                                <li className="spot">#</li>
                                <li className="country">{lang['country']}</li>
                                <li className="medal"><img src={gold_medal} alt={lang['gold']} /></li>
                                <li className="medal"><img src={silver_medal} alt={lang['silver']} /></li>
                                <li className="medal"><img src={bronze_medal} alt={lang['bronze']} /></li>
                                <li className="medal"><img src={medals_icon} alt="total" /></li>
                            </ul>
                        </li>
                        {loadingMedals &&
                            <div className="centerLoading">
                                <img src={loading_gif} alt="loading" />
                            </div>}
                        {!loadingMedals && <>
                            {medals.map((medal: IMedalBoardItem) => <li key={medal.selecao_codigo} className={
                                `${medal.posicao === 1 ? "top" : "other"} ${medal.selecao_codigo === "BRA" ? "brasil" : ""}`
                            }>
                                <ul>
                                    <li className="spot">{medal.posicao}</li>
                                    <li className="country">{medal.selecao_descricao}</li>
                                    <li className="medal">{medal.ouro}</li>
                                    <li className="medal">{medal.prata}</li>
                                    <li className="medal">{medal.bronze}</li>
                                    <li className="medal">{medal.total_medalhas}</li>
                                </ul>
                            </li>)}
                        </>}
                    </ol>
                </div>
            </section>
            {/* <section className="heroImage">
                <img src={hero_banner_footer} alt="Team Brazil" />
            </section> */}
        </main >
        <Footer />
        <BackToTop />
        {modalOpen &&
            <div className={`modalCadastro ${modalOpen ? 'active' : 'nonactive'}`}>
                <div className="overlay" onClick={() => setModalOpen(false)}></div>
                <div className="modalBody">
                    <button className="closeModal" onClick={() => setModalOpen(false)}><img src={close_button} alt="Close" /></button>
                    <big dangerouslySetInnerHTML={{ __html: lang['form']['headline'] }}></big>
                    <span className="heading">{lang['form']['your_pick']}</span>
                    <ul className="sportInfo">
                        <li>
                            <span className="left">{lang['form']['sport']}</span>
                            <span className="right">
                                {/* <img src={require(`../assets/images/icons/sports/${selectedEvent?.icon}`)} alt={selectedEvent?.sport} /> */}
                                {selectedEvent?.sport}
                            </span></li>
                        <li><span className="left">{lang['form']['date']}</span><span className="right">{selectedEvent?.date}</span></li>
                        <li><span className="left">{lang['form']['games']}</span><span className="right">{selectedEvent?.match}</span></li>
                    </ul>
                    <span className="heading">{lang['form']["data"]}</span>
                    <form action="#" onSubmit={(e: any) => handlePostLead(e)}>
                        <div className="formField"><label htmlFor="name">{lang['form']['name']}</label>
                            <input required type="text" name="" id="name" value={leadForm.nome} onChange={(e: any) => handleLeadForm(e, "nome")} />
                        </div>
                        <div className="formField"><label htmlFor="email">{lang['form']['email']}</label>
                            <input required type="email" name="" id="email" value={leadForm.email} onChange={(e: any) => handleLeadForm(e, "email")} /></div>
                        <div className="formField"><label htmlFor="business">{lang['form']['business']}</label>
                            <input required type="text" name="" id="business" value={leadForm.empresa} onChange={(e: any) => handleLeadForm(e, "empresa")} />
                        </div>
                        <div className="formField">
                            <label htmlFor="segment">{lang['form']['segment']}</label>
                            <select name="" id="segment" required value={leadForm.segmento} onChange={(e: any) => handleLeadForm(e, "segmento")}>
                                {leadArea.map((segment: string, index: number) => <option value={segment} key={index}>{segment}</option>)}
                            </select>
                        </div>
                        <div className="formField">
                            <label htmlFor="job">{lang['form']['position']}</label>
                            <select name="job" id="job" required value={leadForm.cargo} onChange={(e: any) => handleLeadForm(e, "cargo")}>
                                {leadJobs.map((segment: string, index: number) => <option value={segment} key={index}>{segment}</option>)}
                            </select>
                        </div>
                        {/* <div className="formField">
                            <label htmlFor="job">{lang['form']['position']}</label>
                            <input required type="text" name="" id="job" value={leadForm.cargo} onChange={(e: any) => handleLeadForm(e, "cargo")} />
                        </div> */}
                        <button type="submit" disabled={loadingLead}>{lang['form']["button"]}</button>
                    </form>
                </div>
            </div>
        }
    </>);
};

export { Calendar };
