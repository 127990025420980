export const languageStrings = {
    "pt_BR": {
        code: "pt_BR",
        headline: "Calendário dos Jogos Paris 2024",
        search: "Pesquise por modalidade",
        all_sports: "Todos os esportes",
        sub_headline: `<b>Nosso time aqui da Skidun é apaixonado por esportes e desenvolveu um calendário interno para não perder nenhum horário dos jogos e deixar a agenda toda organizada.</b><br/><br/>Ficou tão legal que resolvemos passar adiante para ajudar mais pessoas a se organizarem também. Fique à vontade para compartilhar com os colegas.<br /><br />Adicione a programação dos jogos de Paris na sua agenda.`,
        header_prog: "Confira a programação completa",
        // only_team_br: "Ver apenas agenda de esportes coletivos do Time Brasil",
        only_team_br: "Ver apenas agenda do Time Brasil",
        past_event: "Este evento já aconteceu",
        week: {
            "monday": "Seg",
            "tuesday": "Ter",
            "wednesday": "Qua",
            "thursday": "Qui",
            "friday": "Sex",
            "saturday": "Sab",
            "sunday": "Dom",
        },
        month: {
            "july": "Julho",
            "august": "Agosto"
        },
        medal_board: "Quadro de Medalhas",
        add_all: "Adicionar todos os eventos dessa modalidade",
        medal_ev: "Evento de medalha",
        regular_ev: "Evento regular",
        p_day: "Dia provisório",
        top_obs: "*Datas e horários dos jogos são atualizados automaticamente diretamente do site da organizadora do evento. A Skidun não se responsabiliza por qualquer alteração na grade dos jogos.",
        masculine: "Masculino",
        feminine: "Feminino",
        group: "Grupo",
        add_to_calendar: "Adicionar na agenda",
        medal_headline: "Quadro de medalhas atualizado",
        bronze: "Bronze",
        silver: "Prata",
        gold: "Ouro",
        country: "País",
        footer: {
            headline: "E aí, vamos falar sobre os desafios da sua marca?",
            btn_contact: "fale conosco",
            follow: "Acompanhe a Skidun"
        },
        footer_mobile: {
            headline: "Vamos fazer algo incrível juntos!",
            btn_contact: "Fale conosco",
            follow: "Siga a Skidun nas redes sociais"
        },
        sport: "Modalidade",
        date_sch: "Data e horário",
        games: "Jogos",
        form: {
            headline: "<b>Preencha o formulário</b> e adicione na sua agenda agora mesmo!",
            your_pick: "Sua escolha",
            data: "Dados pessoais",
            name: "Nome",
            email: "E-mail",
            business: "Empresa",
            segment: "Area",
            position: "Cargo",
            button: "Adicionar na agenda",
            sport: "Modalidade",
            date: "Data e horário",
            games: "Jogos",
        },
        success_add: "Modalidade adicionada com sucesso!",
        back_button: "Voltar para a página Inicial"
    },
    "en": {
        code: "en",
        headline: "Paris 2024 Olympics Schedule",
        all_sports: "All sports",
        search: "Search by sport",
        sub_headline: "<b>Here at Skidun we're passionte about sports and we developed a calendar so you'll not miss anything from the world's biggest sports event!</b><br/><br/>Add the Paris Olympics schedule now to your calendar.",
        header_prog: "Check the full schedule",
        only_team_br: "Team Brasil collective sports only",
        past_event: "This event has already happened",
        week: {
            "monday": "Mon",
            "tuesday": "Tue",
            "wednesday": "Wed",
            "thursday": "Thu",
            "friday": "Fri",
            "saturday": "Sat",
            "sunday": "Sun",
        },
        month: {
            "july": "July",
            "august": "August"
        },
        medal_board: "Medals Board",
        add_all: "Add all events for this sport",
        medal_ev: "Medal event",
        regular_ev: "Regular event",
        p_day: "Expected day",
        top_obs: "*All competition dates and times can be changed at any time without previous notice.",
        masculine: "Masculine",
        feminine: "Feminine",
        group: "Group",
        add_to_calendar: "Add to my calendar",
        medal_headline: "Updated medals board",
        bronze: "Bronze",
        silver: "Silver",
        gold: "Gold",
        country: "Country",
        footer: {
            headline: "Let's talk about your brand's challenges?",
            btn_contact: "Get in touch",
            follow: "Follow Skidun"
        },
        footer_mobile: {
            headline: "Let's do something amazing together!",
            btn_contact: "Get in touch",
            follow: "Follow Skidun on social media"
        },
        sport: "Sport",
        date_sch: "Date and Time",
        games: "Games",
        form: {
            headline: "<b>Fill the form below</b> and add this event to your calendar now!",
            your_pick: "Your pick",
            sport: "Sport",
            date: "Date and Time",
            games: "Match",
            data: "Personal Data",
            name: "Name",
            email: "E-mail",
            business: "Business",
            segment: "Area",
            position: "Job",
            button: "Add to calendar"
        },
        success_add: "Sport successfully added!",
        back_button: "Back to homepage"
    }
}