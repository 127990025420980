import React from "react";
import { Link } from "react-router-dom";

export interface Props {
}

const NoMatch: React.FC<Props> = () => {
    return (<div>
        <div>NoMatch</div>
        <Link to="/">Home</Link>
    </div>);
};

export { NoMatch };
