import { languageStrings } from "./json/lang/lang_strings";

export default function getLang() {
	let lsLng: string | null = localStorage.getItem("skidunOlympicsLang");
	if (lsLng !== "pt_BR" && lsLng !== "en") {
		lsLng = "pt_BR";
	}
	//@ts-ignore
	return lsLng ? languageStrings[lsLng] : languageStrings["pt_BR"];
}
