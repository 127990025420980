import React, { useEffect, useState } from "react";
import arrow_right from "../assets/images/icons/arrow_right.svg";

export interface Props {
}

const BackToTop: React.FC<Props> = () => {
    const backToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" })
    }
    const [showTop, setShowTop] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 500) {
                setShowTop(true)
            } else {
                setShowTop(false)
            }
        })
    }, [])
    return (
        <div className="backToTopButton">
            {showTop &&
                <button title="Voltar ao topo" onClick={backToTop}><img src={arrow_right} alt="" aria-hidden="true" /></button>
            }
        </div>
    );

};

export { BackToTop };
