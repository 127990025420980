import React from "react";
import getLang from "../helpers/getLang";
import skd_logo from "../assets/images/logo_skidun.svg";
import wpp_logo from "../assets/images/icons/whatsapp.svg";
import ig_logo from "../assets/images/icons/instagram.svg";
// import tt_logo from "../assets/images/icons/twitter.svg";
import li_logo from "../assets/images/icons/linkedin.svg";
import { Link } from "react-router-dom";
// import fb_logo from "../assets/images/icons/facebook.svg";

export interface Props {
}

const Footer: React.FC<Props> = () => {
    const lang = getLang();
    return (
        <footer>
            <div className="container">
                <h6 className="logo">
                    <Link to="/">
                        <img src={skd_logo} alt="Skidun" />
                    </Link>
                </h6>
                <div className="eai">
                    {/* <big className="desktop">{lang['footer']['headline']}</big>
                    <big className="mobile">{lang['footer_mobile']['headline']}</big> */}
                    <a href="mailto:contato@skidun.com.br" rel="noreferrer" target="_blank">
                        Fale com a Skidun</a>
                </div>
                <div className="acompanhe">
                    <p className="desktop">{lang['footer']['follow']}</p>
                    <p className="mobile">{lang['footer_mobile']['follow']}</p>
                    <ul className="social">
                        {/* <li><a target="_blank" rel="noreferrer" href="https://api.whatsapp.com/send/?phone=5521981289980&text=Oi%2C+Skidun%21+Tem+algu%C3%A9m+a%C3%AD%3F&type=phone_number&app_absent=0"><img src={wpp_logo} alt="Whatsapp" /></a></li> */}
                        <li><a target="_blank" rel="noreferrer" href="https://www.instagram.com/skidun/"><img src={ig_logo} alt="Instagram" /></a></li>
                        <li><a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/skidun"><img src={li_logo} alt="Linkedin" /></a></li>
                        {/* <li><a target="_blank" rel="noreferrer" href="https://x.com/Skidun"><img src={tt_logo} alt="Twitter / X" /></a></li> */}
                        {/* <li><a target="_blank" rel="noreferrer" href="https://www.facebook.com/Skidun"><img src={fb_logo} alt="Facebook" /></a></li> */}
                    </ul>
                </div>
            </div>
        </footer>
    );

};

export { Footer };
