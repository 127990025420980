import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import getLang from "../helpers/getLang";
import skidun_logo_big from "../assets/images/logo-ag-digital.svg";
import arrow_right from "../assets/images/icons/arrow_right.svg";

export interface Props {
}

const Home: React.FC<Props> = () => {
    const lang = getLang();
    useEffect(() => { window.scrollTo(0, 0); }, [])
    return (
        <header className="homeHeader">
            <div className="container">
                <h1><a href="https://skidun.com/?utm_source=home_calendario" target="_blank" rel="noreferrer"><img src={skidun_logo_big} alt="Skidun" width={160} /></a></h1>
                <h2>{lang["headline"]}</h2>
                <p dangerouslySetInnerHTML={{ __html: lang["sub_headline"] }}></p>
                <Link className="buttonCalendar" to="calendar">Acessar o Calendário <img src={arrow_right} alt="" aria-hidden="true" /></Link>
            </div>
        </header>
    );
};

export { Home };
