import React from "react";
import getLang from "../helpers/getLang";
import skidun_logo_big from "../assets/images/skidun_logo_big.svg";
import { Link } from "react-router-dom";

export interface Props {
}

const Header: React.FC<Props> = () => {
    const lang = getLang();
    return (
        <header className="mainHeader">
            <div className="container">
                <div className="topLink">
                    <h1><Link to="/calendar"><img src={skidun_logo_big} alt="Skidun" width={160} /></Link></h1>
                    <span><a href="https://skidun.com/?utm_source=header_interna_calendario" target="_blank" rel="noreferrer">Site Skidun</a></span>
                </div>
                <h2>{lang['headline']}</h2>
            </div>
        </header>
    );

};

export { Header };
