import React from "react";


import { Routes, Route } from "react-router-dom";
import { Home } from "./pages/home";
import { Calendar } from "./pages/calendar";
import { NoMatch } from "./pages/404";
import { Thanks } from "./pages/thanks";

const Rotas: React.FC = () => {
    return (<Routes>
        <Route path="/" element={<Home />} />
        <Route path="/calendar" element={<Calendar />} />
        <Route path="/thanks" element={<Thanks />} />
        <Route path="*" element={<NoMatch />} />
    </Routes>
    );
};


export { Rotas };