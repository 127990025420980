export interface IOlympicSport {
	id: number;
	name: string;
	name_pt_br: string;
	icon: string;
}
export const olympicSports = [
	{
		"id": 1,
		"name": "Archery",
		"icon": "archery.svg",
		"name_pt_br": "Tiro com Arco",
	},
	{
		"id": 2,
		"name": "Artistic Swimming",
		"icon": "artistic_swimming.svg",
		"name_pt_br": "Nado Artístico",
	},
	{
		"id": 3,
		"name": "Athletics",
		"icon": "athletics.svg",
		"name_pt_br": "Atletismo",
	},
	{
		"id": 4,
		"name": "Badminton",
		"icon": "badminton.svg",
		"name_pt_br": "Badminton",
	},
	{
		"id": 5,
		"name": "Basketball",
		"icon": "basketball.svg",
		"name_pt_br": "Basquete",
	},
	{
		"id": 6,
		"name": "Boxing",
		"icon": "boxing.svg",
		"name_pt_br": "Boxe",
	},
	{
		"id": 7,
		"name": "Breaking",
		"icon": "breaking.svg",
		"name_pt_br": "Breaking",
	},
	{
		"id": 8,
		"name": "Canoe Slalom",
		"icon": "canoe_slalom.svg",
		"name_pt_br": "Canoagem Slalom",
	},
	{
		"id": 9,
		"name": "Canoe Sprint",
		"icon": "canoe_sprint.svg",
		"name_pt_br": "Canoagem Velocidade",
	},
	{
		"id": 10,
		"name": "Cycling BMX Freestyle",
		"icon": "cycling_bmx_freestyle.svg",
		"name_pt_br": "Ciclismo BMX Freestyle",
	},
	{
		"id": 11,
		"name": "Cycling BMX Racing",
		"icon": "cycling_bmx_racing.svg",
		"name_pt_br": "Ciclismo BMX Racing",
	},
	{
		"id": 12,
		"name": "Cycling Mountain Bike",
		"icon": "cycling_mountain_bike.svg",
		"name_pt_br": "Ciclismo Mountain Bike",
	},
	{
		"id": 13,
		"name": "Cycling Road",
		"icon": "cycling_road.svg",
		"name_pt_br": "Ciclismo de Estrada",
	},
	{
		"id": 14,
		"name": "Cycling Track",
		"icon": "cycling_track.svg",
		"name_pt_br": "Ciclismo de Pista",
	},
	{
		"id": 15,
		"name": "Diving",
		"icon": "diving.svg",
		"name_pt_br": "Saltos Ornamentais",
	},
	{
		"id": 16,
		"name": "Equestrian",
		"icon": "equestrian.svg",
		"name_pt_br": "Hipismo",
	},
	{
		"id": 17,
		"name": "Fencing",
		"icon": "fencing.svg",
		"name_pt_br": "Esgrima",
	},
	{
		"id": 18,
		"name": "Football",
		"icon": "football.svg",
		"name_pt_br": "Futebol",
	},
	{
		"id": 19,
		"name": "Golf",
		"icon": "golf.svg",
		"name_pt_br": "Golfe",
	},
	{
		"id": 20,
		"name": "Gymnastics Artistic",
		"icon": "gym_art.svg",
		"name_pt_br": "Ginástica Artística",
	},
	{
		"id": 21,
		"name": "Gymnastics Rhythmic",
		"icon": "gymnastics_rhythmic.svg",
		"name_pt_br": "Ginástica Rítmica",
	},
	{
		"id": 22,
		"name": "Handball",
		"icon": "handball.svg",
		"name_pt_br": "Handebol",
	},
	{
		"id": 23,
		"name": "Hockey",
		"icon": "hockey.svg",
		"name_pt_br": "Hóquei sobre Grama",
	},
	{
		"id": 24,
		"name": "Judo",
		"icon": "judo.svg",
		"name_pt_br": "Judô",
	},
	{
		"id": 25,
		"name": "Modern Pentathlon",
		"icon": "modern_pentathlon.svg",
		"name_pt_br": "Pentatlo Moderno",
	},
	{
		"id": 26,
		"name": "Rowing",
		"icon": "rowing.svg",
		"name_pt_br": "Remo",
	},
	{
		"id": 27,
		"name": "Rugby Sevens",
		"icon": "rugby_sevens.svg",
		"name_pt_br": "Rugby Sevens",
	},
	{
		"id": 28,
		"name": "Sailing",
		"icon": "sailing.svg",
		"name_pt_br": "Vela",
	},
	{
		"id": 29,
		"name": "Shooting",
		"icon": "shooting.svg",
		"name_pt_br": "Tiro Esportivo",
	},
	{
		"id": 30,
		"name": "Skateboarding",
		"icon": "skateboarding.svg",
		"name_pt_br": "Skate",
	},
	{
		"id": 31,
		"name": "Sport Climbing",
		"icon": "sport_climbing.svg",
		"name_pt_br": "Escalada Esportiva",
	},
	{
		"id": 32,
		"name": "Surfing",
		"icon": "surfing.svg",
		"name_pt_br": "Surfe",
	},
	{
		"id": 33,
		"name": "Swimming",
		"icon": "swimming.svg",
		"name_pt_br": "Natação",
	},
	{
		"id": 34,
		"name": "Table Tennis",
		"icon": "table_tennis.svg",
		"name_pt_br": "Tênis de Mesa",
	},
	{
		"id": 35,
		"name": "Taekwondo",
		"icon": "taekwondo.svg",
		"name_pt_br": "Taekwondo",
	},
	{
		"id": 36,
		"name": "Tennis",
		"icon": "tennis.svg",
		"name_pt_br": "Tênis",
	},
	{
		"id": 37,
		"name": "Triathlon",
		"icon": "triathlon.svg",
		"name_pt_br": "Triatlo",
	},
	{
		"id": 38,
		"name": "Volleyball",
		"icon": "volleyball.svg",
		"name_pt_br": "Vôlei",
	},
	{
		"id": 39,
		"name": "Water Polo",
		"icon": "water_polo.svg",
		"name_pt_br": "Polo Aquático",
	},
	{
		"id": 40,
		"name": "Weightlifting",
		"icon": "weightlifting.svg",
		"name_pt_br": "Levantamento de Peso",
	},
	{
		"id": 41,
		"name": "Wrestling",
		"icon": "wrestling.svg",
		"name_pt_br": "Luta",
	},
	{
		"id": 42,
		"name": "Trampoline",
		"icon": "trampoline.svg",
		"name_pt_br": "Ginástica de Trampolim",
	},
	{
		"id": 44,
		"name": "Marathon Swimming",
		"icon": "marathon_swimming.svg",
		"name_pt_br": "Maratona Aquática",
	},
	{
		"id": 45,
		"name": "Beach Volleyball",
		"icon": "beach_volleyball.svg",
		"name_pt_br": "Vôlei de Praia",
	},
	{
		"id": 46,
		"name": "Basketball 3x3",
		"icon": "3x3-basketball.svg",
		"name_pt_br": "Basquete 3x3",
	},
];
