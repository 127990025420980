import React from 'react';
import { Outlet } from 'react-router-dom';
import { Rotas } from './routes';
import { Footer } from './components/footer';
import "./styles/variables.css";
import "./styles/App.css";
import "./styles/fonts.css";
import "./styles/footer.css";
import "./styles/header.css";
import "./styles/main.css";
import "./styles/thanks.css";
import "./styles/mobile.css";

function App() {
  return (<>
    <Rotas />
    <Outlet />
  </>
  );
}

export default App;